<template>
  <div id="page-root">
    <div class="button_nav">
      <div class="button" @click="_back">
        <el-icon class="icon_box">
          <Back />
        </el-icon>
        <div class="back_text">返回</div>
      </div>
    </div>
    <div class="content_nav">
      <div class="article-container">
        <h2 class="title">
          首秀惊艳，未来可期 | 首届数贸会在杭州圆满落幕，互为“绿能专家”收获颇丰！
        </h2>
        <div class="article-info">
          <span class="yuanchuang">原创</span><span class="green">绿能派</span
          ><span class="timestamp">2022-12-20 15:30</span>
        </div>
        <div class="article-content-wrap">
          <div class="article-content">
            <div
              style="
                max-width: 100%;
                box-sizing: border-box;
                color: rgb(62, 62, 62);
                font-size: 16px;
                background-color: rgb(255, 255, 255);
                margin-bottom: 0px;
              "
            >
              <div
                style="
                  padding-right: 10px;
                  padding-left: 10px;
                  max-width: 100%;
                  box-sizing: border-box;
                  line-height: 1.6;
                "
              >
                <p style="text-align: center"><br /></p>
                <table align="center">
                  <tbody>
                    <tr class="firstRow">
                      <td
                        valign="middle"
                        style="border-color: rgb(0, 209, 0)"
                        align="left"
                      >
                        <p style="line-height: 1.6em">
                          <span
                            style="
                              color: rgb(62, 62, 62);
                              background-color: rgb(255, 255, 255);
                              font-size: 24px;
                            "
                            ><strong
                              style="max-width: 100%; box-sizing: border-box !important"
                              ><span
                                style="
                                  max-width: 100%;
                                  color: rgb(136, 136, 136);
                                  box-sizing: border-box !important;
                                "
                                >“</span
                              ></strong
                            ><span
                              style="
                                max-width: 100%;
                                color: rgb(136, 136, 136);
                                box-sizing: border-box !important;
                              "
                              >&nbsp;</span
                            ></span
                          ><span
                            style="
                              font-size: 16px;
                              background-color: rgb(255, 255, 255);
                              max-width: 100%;
                              color: rgb(136, 136, 136);
                              box-sizing: border-box !important;
                            "
                            >12月14日，首届全球数字贸易博览会在杭州国际博览中心圆满落幕，各类展览和论坛顺利召开，“绿能专家”首秀表现惊艳，不仅获得官方认可，还收获一众粉丝青睐。</span
                          ><span
                            style="
                              background-color: rgb(255, 255, 255);
                              max-width: 100%;
                              color: rgb(136, 136, 136);
                              font-size: 24px;
                              box-sizing: border-box !important;
                            "
                            ><strong
                              style="max-width: 100%; box-sizing: border-box !important"
                              >”</strong
                            ></span
                          >
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
              </div>
            </div>
            <div
              style="
                max-width: 100%;
                box-sizing: border-box;
                color: rgb(62, 62, 62);
                font-size: 16px;
                background-color: rgb(255, 255, 255);
              "
            >
              <div
                style="
                  padding-right: 20px;
                  padding-left: 20px;
                  max-width: 100%;
                  box-sizing: border-box;
                  line-height: 0.8;
                "
              >
                <p style="text-align: center">
                  <img
                    src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_png/MBcic5TYfW3augKXMJRM9Q1fxzyuFNIfPE2rMvibLmXloF3bpefzJpicOTNwUPqbK7jjLjvyLnMcibxHw8Zv3MsPyQ/640?wx_fmt=png"
                    style="width: 168px; height: 74px"
                  />
                </p>
              </div>
            </div>
            <p
              style="
                max-width: 100%;
                min-height: 1em;
                color: rgb(62, 62, 62);
                font-size: 16px;
                background-color: rgb(255, 255, 255);
                text-align: center;
                box-sizing: border-box !important;
                margin-bottom: 0px;
              "
            >
              <span
                style="
                  max-width: 100%;
                  font-size: 18px;
                  box-sizing: border-box !important;
                "
                >政府领导视察参观</span
              >
            </p>
            <p
              style="
                max-width: 100%;
                min-height: 1em;
                color: rgb(62, 62, 62);
                font-size: 16px;
                background-color: rgb(255, 255, 255);
                box-sizing: border-box !important;
                margin-bottom: 0px;
              "
            >
              <br />
            </p>
            <p
              style="
                max-width: 100%;
                min-height: 1em;
                color: rgb(62, 62, 62);
                font-size: 16px;
                background-color: rgb(255, 255, 255);
                box-sizing: border-box !important;
                margin-bottom: 0px;
              "
            >
              12月12日上午，<strong
                >浙江省文化和旅游厅<span
                  style="
                    color: rgb(62, 62, 62);
                    background-color: rgb(255, 255, 255);
                    font-size: 16px;
                  "
                  >党组书记陈广胜</span
                ></strong
              ><span
                style="
                  color: rgb(62, 62, 62);
                  background-color: rgb(255, 255, 255);
                  font-size: 16px;
                "
                >，<strong
                  ><span
                    style="
                      color: rgb(62, 62, 62);
                      background-color: rgb(255, 255, 255);
                      font-size: 16px;
                    "
                    >副厅长、党组成员、一级巡视员<span
                      style="
                        color: rgb(62, 62, 62);
                        background-color: rgb(255, 255, 255);
                        font-size: 16px;
                      "
                      >许澎</span
                    ></span
                  ></strong
                ><span
                  style="
                    color: rgb(62, 62, 62);
                    background-color: rgb(255, 255, 255);
                    font-size: 16px;
                  "
                  ><span
                    style="
                      color: rgb(62, 62, 62);
                      background-color: rgb(255, 255, 255);
                      font-size: 16px;
                    "
                    >等相关</span
                  ></span
                ></span
              >领导，先后来到互为公司展厅视察，在听取展品汇报及演示后，给予高度认可和评价，并提出指导意见，希望互为产品大力推动酒店行业节能减排，实现降本增效，<span
                style="
                  color: rgb(62, 62, 62);
                  background-color: rgb(255, 255, 255);
                  font-size: 16px;
                "
                >助力<span
                  style="
                    color: rgb(62, 62, 62);
                    font-size: 16px;
                    background-color: rgb(255, 255, 255);
                  "
                  >文旅产业</span
                >快速复苏</span
              >。
            </p>
            <p
              style="
                max-width: 100%;
                min-height: 1em;
                color: rgb(62, 62, 62);
                font-size: 16px;
                background-color: rgb(255, 255, 255);
                box-sizing: border-box !important;
                margin-bottom: 0px;
              "
            >
              <br />
            </p>
            <p style="margin-bottom: 0px">
              <span style="font-size: 13px"
                ><span
                  style="
                    font-size: 13px;
                    text-align: left;
                    color: rgb(34, 34, 34);
                    background-color: rgb(255, 255, 255);
                  "
                  ><img
                    src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_jpg/MBcic5TYfW3Z5aXXrC2PWF1kMBXVk7tMNNib7ywpiaCric0IS2BXkYYhuneMHbTRPQ83rWcYOCGM8ctFrpaxAPdib0A/640?wx_fmt=jpeg"
                    style="text-align: center"
                  />▲</span
                ><span style="font-size: 13px; text-align: left"
                  >浙江省文化和旅游厅党组书记&nbsp; 陈广胜</span
                ></span
              >
            </p>
            <div style="text-align: center; line-height: normal"><br /></div>
            <div style="text-align: left; line-height: normal">
              <span style="font-size: 13px"
                ><span
                  style="
                    font-size: 13px;
                    color: rgb(34, 34, 34);
                    background-color: rgb(255, 255, 255);
                  "
                  ><img
                    src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_jpg/MBcic5TYfW3Z5aXXrC2PWF1kMBXVk7tMNoIXCfmEOGsmwYhRF88X0hkuic5Pf7K1ag9SaV7WMZ7RaL39c1m6h3HQ/640?wx_fmt=jpeg"
                    style="text-align: center"
                  />▲</span
                >浙江省文化和旅游厅副厅长、党组成员、一级巡视员&nbsp; 许澎</span
              >
            </div>
            <div
              style="
                max-width: 100%;
                min-height: 1em;
                color: rgb(62, 62, 62);
                font-size: 16px;
                background-color: rgb(255, 255, 255);
                margin-bottom: 0px;
                box-sizing: border-box !important;
              "
            >
              <div
                style="
                  max-width: 100%;
                  min-height: 1em;
                  color: rgb(62, 62, 62);
                  font-size: 16px;
                  background-color: rgb(255, 255, 255);
                  margin-bottom: 0px;
                  box-sizing: border-box !important;
                "
              >
                <div
                  style="
                    max-width: 100%;
                    min-height: 1em;
                    color: rgb(62, 62, 62);
                    font-size: 16px;
                    background-color: rgb(255, 255, 255);
                    margin-bottom: 0px;
                    box-sizing: border-box !important;
                  "
                >
                  <div
                    style="
                      max-width: 100%;
                      min-height: 1em;
                      color: rgb(62, 62, 62);
                      font-size: 16px;
                      background-color: rgb(255, 255, 255);
                      margin-bottom: 0px;
                      box-sizing: border-box !important;
                    "
                  >
                    <p
                      style="
                        max-width: 100%;
                        min-height: 1em;
                        color: rgb(62, 62, 62);
                        font-size: 16px;
                        background-color: rgb(255, 255, 255);
                        margin-bottom: 0px;
                        box-sizing: border-box !important;
                      "
                    >
                      <br />
                    </p>
                    <p style="max-width: 100%; box-sizing: border-box; min-height: 1em">
                      <span
                        style="
                          max-width: 100%;
                          text-align: center;
                          box-sizing: border-box !important;
                        "
                      ></span>
                    </p>
                    <div style="max-width: 100%; box-sizing: border-box">
                      <div style="max-width: 100%; box-sizing: border-box">
                        <div style="max-width: 100%; box-sizing: border-box">
                          <div
                            style="
                              padding-right: 20px;
                              padding-left: 20px;
                              max-width: 100%;
                              box-sizing: border-box;
                              line-height: 0.8;
                            "
                          >
                            <p style="text-align: center">
                              <img
                                src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_png/MBcic5TYfW3augKXMJRM9Q1fxzyuFNIfPku7Ic8r8FkKa1iba3ZvqYEXrQ6YjcFfz4En4PGpAic5BE7ZbrKe4bsDA/640?wx_fmt=png"
                                style="
                                  height: 74px;
                                  color: rgb(62, 62, 62);
                                  font-size: 16px;
                                  text-align: center;
                                  background-color: rgb(255, 255, 255);
                                  width: 168px;
                                "
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p
                      style="
                        max-width: 100%;
                        box-sizing: border-box;
                        min-height: 1em;
                        text-align: center;
                      "
                    >
                      <span
                        style="
                          max-width: 100%;
                          font-size: 18px;
                          box-sizing: border-box !important;
                        "
                        >媒体报道好评如潮</span
                      >
                    </p>
                    <p
                      style="
                        max-width: 100%;
                        box-sizing: border-box;
                        min-height: 1em;
                        text-align: center;
                      "
                    >
                      <span
                        style="
                          max-width: 100%;
                          font-size: 20px;
                          box-sizing: border-box !important;
                        "
                        ><br
                      /></span>
                    </p>
                    <p style="max-width: 100%; box-sizing: border-box; min-height: 1em">
                      “绿能专家”科学、<span
                        style="
                          color: rgb(62, 62, 62);
                          font-size: 16px;
                          background-color: rgb(255, 255, 255);
                        "
                        >新颖等特点，以及“客观+主观+储能”的低碳节能理念，获得媒体的报道和好评。展出期间，<strong>澎湃新闻</strong>、<strong>网易新闻</strong>等多家媒体开展了现场采访，并通过视频、图文等形式报道。</span
                      ><br style="max-width: 100%; box-sizing: border-box !important" />
                    </p>
                    <p style="text-align: center"><br /></p>
                    <div>
                      <video
                        id="video"
                        type="video/mp4"
                        class="video_play"
                        x-webkit-airplay="true"
                        playsinline="true"
                        controls
                        x5-video-player-type="h5"
                        preload="auto"
                        src="https://sys.huwei.xin:7443/huwei-website/video/2.mp4"
                      ></video>
                    </div>
                    <div>
                      <span
                        style="text-align: left; font-size: 13px; color: rgb(34, 34, 34)"
                        >▲</span
                      ><span style="text-align: left; font-size: 13px">澎湃新闻采访</span>
                    </div>
                    <p style="text-align: left"><span style="font-size: 13px"></span></p>
                    <p style="text-align: left">
                      <span style="font-size: 13px"><br /></span
                      ><img
                        src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_png/MBcic5TYfW3a6FoYrGgW1PZdIBiaTYCruARCiaOm6qS8ib6qX1ZD72YVb3dgNwdXOSp1WsOWkWa7Erw8vJiaticib1yiaw/640?wx_fmt=png"
                        style="
                          color: rgb(62, 62, 62);
                          font-size: 16px;
                          text-align: center;

                          background-color: rgb(255, 255, 255);
                        "
                      />
                    </p>
                    <p style="text-align: left">
                      <span
                        style="
                          text-align: left;
                          font-size: 13px;
                          color: rgb(34, 34, 34);

                          background-color: rgb(255, 255, 255);
                        "
                        >▲</span
                      ><span style="font-size: 13px">网易新闻报道</span>
                    </p>
                    <p style="text-align: center"><br /></p>
                    <p style="text-align: center"><br /></p>
                    <div
                      style="margin-bottom: 0px; max-width: 100%; box-sizing: border-box"
                    >
                      <div style="max-width: 100%; box-sizing: border-box">
                        <div style="max-width: 100%; box-sizing: border-box">
                          <div
                            style="
                              padding-right: 20px;
                              padding-left: 20px;
                              max-width: 100%;
                              box-sizing: border-box;
                              line-height: 0.8;
                            "
                          >
                            <p style="text-align: center">
                              <img
                                src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_png/MBcic5TYfW3augKXMJRM9Q1fxzyuFNIfPGRzGlEw1AiaAdFNGIGQEWCx1WFicarmCSUI44cFOkBS8Or4e0sHHkk3A/640?wx_fmt=png"
                                style="
                                  height: 74px;
                                  color: rgb(62, 62, 62);
                                  font-size: 16px;
                                  text-align: center;

                                  background-color: rgb(255, 255, 255);
                                  width: 168px;
                                "
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p
                      style="
                        margin-bottom: 0px;
                        max-width: 100%;
                        box-sizing: border-box;
                        text-align: center;
                      "
                    >
                      <span
                        style="
                          color: rgb(62, 62, 62);
                          text-align: center;
                          background-color: rgb(255, 255, 255);
                          max-width: 100%;
                          font-size: 18px;
                          box-sizing: border-box !important;
                        "
                        >现场活动人气爆棚</span
                      >
                    </p>
                    <p
                      style="
                        margin-bottom: 0px;
                        max-width: 100%;
                        box-sizing: border-box;
                        text-align: center;
                      "
                    >
                      <span
                        style="
                          max-width: 100%;
                          font-size: 20px;
                          box-sizing: border-box !important;
                        "
                        ><span
                          style="
                            color: rgb(62, 62, 62);
                            font-size: 20px;
                            text-align: center;
                            background-color: rgb(255, 255, 255);
                          "
                          ><br /></span
                      ></span>
                    </p>
                  </div>
                  <div
                    style="
                      max-width: 100%;
                      min-height: 1em;
                      color: rgb(62, 62, 62);
                      font-size: 16px;
                      background-color: rgb(255, 255, 255);
                      margin-bottom: 0px;
                      box-sizing: border-box !important;
                    "
                  >
                    展出期间，互为公司展台前始终人气十足，观众从一开始的驻足好奇，到主动咨询、认可、互留信息。
                  </div>
                  <div
                    style="
                      max-width: 100%;
                      min-height: 1em;
                      color: rgb(62, 62, 62);
                      font-size: 16px;

                      background-color: rgb(255, 255, 255);
                      margin-bottom: 0px;
                      box-sizing: border-box !important;
                    "
                  >
                    <br />
                  </div>
                  <div
                    style="
                      max-width: 100%;
                      min-height: 1em;
                      color: rgb(62, 62, 62);
                      font-size: 16px;

                      background-color: rgb(255, 255, 255);
                      margin-bottom: 0px;
                      box-sizing: border-box !important;
                    "
                  >
                    <img
                      src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_jpg/MBcic5TYfW3bIsbGcc8TonN16L039hmrOhXucrKEBWAmofrmNjzfk6ndgsSMvwgZkCuaHvwkGKnAcC4UOtmYZXg/640?wx_fmt=jpeg"
                      style="
                        color: rgb(62, 62, 62);
                        font-size: 16px;
                        text-align: left;

                        background-color: rgb(255, 255, 255);
                      "
                    /><span
                      style="
                        text-align: left;
                        font-size: 13px;
                        color: rgb(34, 34, 34);
                        background-color: rgb(255, 255, 255);
                      "
                      >▲</span
                    ><span
                      style="
                        color: rgb(62, 62, 62);
                        text-align: left;
                        background-color: rgb(255, 255, 255);
                        font-size: 13px;
                      "
                      >现场讲解</span
                    >
                  </div>
                  <div
                    style="
                      max-width: 100%;
                      min-height: 1em;
                      color: rgb(62, 62, 62);
                      font-size: 16px;

                      background-color: rgb(255, 255, 255);
                      margin-bottom: 0px;
                      box-sizing: border-box !important;
                    "
                  >
                    <span
                      style="
                        color: rgb(62, 62, 62);
                        text-align: left;
                        background-color: rgb(255, 255, 255);
                        font-size: 13px;
                      "
                      ><br
                    /></span>
                  </div>
                  <div
                    style="
                      max-width: 100%;
                      min-height: 1em;
                      color: rgb(62, 62, 62);
                      font-size: 16px;

                      background-color: rgb(255, 255, 255);
                      margin-bottom: 0px;
                      box-sizing: border-box !important;
                    "
                  >
                    同时，我们组织开展的“扫码签住赢礼品”现场活动，更是吸引了众多粉丝，通过现场模拟酒店签住体验，让更多人深入了解低碳住宿，提高绿色生活意识。
                  </div>
                  <div
                    style="
                      max-width: 100%;
                      min-height: 1em;
                      color: rgb(62, 62, 62);
                      font-size: 16px;

                      background-color: rgb(255, 255, 255);
                      margin-bottom: 0px;
                      box-sizing: border-box !important;
                    "
                  >
                    <p
                      style="
                        margin-bottom: 0px;
                        max-width: 100%;
                        box-sizing: border-box;
                        text-align: center;
                      "
                    >
                      <span
                        style="
                          max-width: 100%;
                          font-size: 20px;
                          box-sizing: border-box !important;
                        "
                        ><br
                      /></span>
                    </p>
                    <p style="text-align: left">
                      <img
                        src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_jpg/MBcic5TYfW3bIsbGcc8TonN16L039hmrOQxdeY3zo5SEbd828hARKPqpNwCIka4Pibfp3mFe8MCCSVpibjd1AwOLA/640?wx_fmt=jpeg"
                      /><span
                        style="
                          text-align: left;
                          font-size: 13px;
                          color: rgb(34, 34, 34);

                          background-color: rgb(255, 255, 255);
                        "
                        >▲</span
                      ><span
                        style="
                          color: rgb(62, 62, 62);
                          text-align: left;
                          background-color: rgb(255, 255, 255);
                          font-size: 13px;
                        "
                        >现场活动体验</span
                      ><span
                        style="
                          color: rgb(62, 62, 62);
                          text-align: left;
                          background-color: rgb(255, 255, 255);
                          font-size: 13px;
                        "
                      ></span>
                    </p>
                    <p
                      style="
                        margin-bottom: 0px;
                        max-width: 100%;
                        box-sizing: border-box;
                        text-align: center;
                      "
                    >
                      <br />
                    </p>
                    <p
                      style="
                        margin-bottom: 0px;
                        max-width: 100%;
                        box-sizing: border-box;
                        text-align: center;
                      "
                    >
                      <br />
                    </p>
                    <div
                      style="margin-bottom: 0px; max-width: 100%; box-sizing: border-box"
                    >
                      <div style="max-width: 100%; box-sizing: border-box">
                        <div style="max-width: 100%; box-sizing: border-box">
                          <div
                            style="
                              padding-right: 20px;
                              padding-left: 20px;
                              max-width: 100%;
                              box-sizing: border-box;
                              line-height: 0.8;
                            "
                          >
                            <p style="text-align: center">
                              <img
                                src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_png/MBcic5TYfW3augKXMJRM9Q1fxzyuFNIfPvzWics5zuyosnTXvJllOhn2PfRlclp4hEBK3x6y59weo6NmqvSTLAAQ/640?wx_fmt=png"
                                style="
                                  height: 74px;
                                  color: rgb(62, 62, 62);
                                  font-size: 16px;
                                  text-align: center;
                                  background-color: rgb(255, 255, 255);
                                  width: 168px;
                                "
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p
                      style="
                        margin-bottom: 0px;
                        max-width: 100%;
                        box-sizing: border-box;
                        text-align: center;
                      "
                    >
                      <span
                        style="
                          max-width: 100%;
                          font-size: 18px;
                          box-sizing: border-box !important;
                        "
                        >意向合作诚意满满</span
                      >
                    </p>
                    <p
                      style="margin-bottom: 0px; max-width: 100%; box-sizing: border-box"
                    >
                      <br />
                    </p>
                    <p
                      style="margin-bottom: 0px; max-width: 100%; box-sizing: border-box"
                    >
                      在了解“绿能专家”的产品特点和价值后，不少酒店、投资人、招商局等类型观众，表达出了强烈的合作意向，纷纷表示后续要深入交流，并看好合作项目的落地实施。
                    </p>
                    <p
                      style="margin-bottom: 0px; max-width: 100%; box-sizing: border-box"
                    >
                      <br />
                    </p>
                    <p style="text-align: left">
                      <img
                        src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_jpg/MBcic5TYfW3Z5aXXrC2PWF1kMBXVk7tMNgBrzVC9icDJkPBXTxu1boIW6gouudaQrrtGZ8lFN7lPoujzh1qyO9Vg/640?wx_fmt=jpeg"
                      /><span
                        style="
                          text-align: left;
                          font-size: 13px;
                          color: rgb(34, 34, 34);
                          background-color: rgb(255, 255, 255);
                        "
                        >▲合作</span
                      ><span
                        style="
                          color: rgb(62, 62, 62);
                          text-align: left;
                          background-color: rgb(255, 255, 255);
                          font-size: 13px;
                        "
                        >意</span
                      ><span
                        style="
                          color: rgb(62, 62, 62);
                          text-align: left;
                          background-color: rgb(255, 255, 255);
                          font-size: 13px;
                        "
                        >向观众</span
                      >
                    </p>
                    <p style="text-align: left">
                      <span
                        style="
                          color: rgb(62, 62, 62);
                          text-align: left;
                          background-color: rgb(255, 255, 255);
                          font-size: 13px;
                        "
                        ><br
                      /></span>
                    </p>
                    <p style="text-align: left">
                      <img
                        src="https://wximg.yiban.io/img_proxy?src=https://mmbiz.qpic.cn/mmbiz_jpg/MBcic5TYfW3ZxPYQcPicsicYYJibD99Q2Oe2JAeicp2y0jGtFCpblQFBhCYmjdd6rfF3CjWw5hzvicP4XoDWR1g9cEWQ/640?wx_fmt=jpeg"
                      /><span
                        style="
                          text-align: left;
                          font-size: 13px;
                          color: rgb(34, 34, 34);
                          background-color: rgb(255, 255, 255);
                        "
                        >▲</span
                      ><span
                        style="
                          color: rgb(62, 62, 62);
                          text-align: left;
                          background-color: rgb(255, 255, 255);
                          font-size: 13px;
                        "
                        >合作</span
                      ><span
                        style="
                          color: rgb(62, 62, 62);
                          text-align: left;
                          background-color: rgb(255, 255, 255);
                          font-size: 13px;
                        "
                        >意向观众</span
                      >
                    </p>
                    <p style="text-align: left"><br /></p>
                    <p
                      style="
                        max-width: 100%;
                        min-height: 1em;
                        box-sizing: border-box !important;
                      "
                    >
                      这次展出让更多人知道“绿能专家”，也获得普遍的认可，我们未来将继续坚定方向，充分发挥它的价值，推动酒店行业绿色发展，促进文旅产业快速复苏，全面响应“双碳”战略目标，将“每人每天省一度电”的愿景，一步步变成现实。<br />
                    </p>
                    <p
                      style="
                        max-width: 100%;
                        min-height: 1em;
                        box-sizing: border-box !important;
                      "
                    >
                      <br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <p style="display: none"></p>
          </div>
          <div class="article-footer"></div>
        </div>
      </div>
      <div class="list_nav">
        <div class="title_box">
          <img src="https://sys.huwei.xin:7443/huwei-website/icon/list_icon.png" alt="" />
          全部列表
        </div>
        <div class="list_box">
          <div
            class="list"
            :class="index == 0 ? 'new_box' : ''"
            @click="go(index)"
            v-for="(item, index) in informationList"
            :key="item.title"
          >
            <img
              class="img"
              :src="'https://sys.huwei.xin:7443/huwei-website/' + item.img"
              alt=""
            />
            <div class="list_content">
              <div class="title">{{ item.title }}</div>
              <div class="bottom">
                <div>
                  <span
                    ><img
                      src="https://sys.huwei.xin:7443/huwei-website/icon/time_icon.png"
                      alt="" /></span
                  >{{ item.date }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { Back } from "@element-plus/icons";
export default {
  name: "greenEnergy",
  components: { Back },
  setup() {
    const dom = getCurrentInstance();
    const state = computed(() => useStore().state.client);
    const router = useRouter();
    const informationList = ref([
      {
        img: "information-1.png",
        title: "首秀惊艳，未来可期 | 首届数贸会在杭州圆满落幕，互为“绿能专家”收获颇丰！",
        date: "2022-12-20  15:30",
      },
      {
        img: "information-2.png",
        title: "行业动态 | 2022年浙江省饭店业协会年度风云大会",
        date: "2022-09-01  16:01",
      },
    ]);
    const _back = () => {
      router.push("/about");
    };
    const go = (i) => {
      if (i == 0) {
        router.push("/information");
      } else if (i == 1) {
        router.push("/information_2");
      }
    };
    return {
      dom,
      state,
      _back,
      informationList,
      go,
    };
  },
};
</script>
<style lang="scss" scoped>
.content_nav {
  display: grid;
  padding: 0 168px 0 276px;
  grid-template-columns: 900px auto;
  grid-gap: 69px;
  line-height: 25px;
}

.list_nav {
  .title_box {
    display: flex;
    align-items: center;
    justify-content: start;
    font-size: 26px;
    color: #222222;
    line-height: 35px;

    img {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }

  .list_box {
    padding-top: 10px;

    .list {
      display: flex;
      padding: 10px;
      position: relative;
      cursor: pointer;

      .new {
        position: absolute;
        background: #000000;
        border-radius: 5px;
        font-size: 14px;
        font-weight: 300;
        color: #ffffff;
        line-height: 20px;
        padding: 5px 20px;
        top: 50px;
        left: 50px;
        opacity: 0.53;
      }

      .img {
        width: 195px;
        height: 131px;
      }

      .list_content {
        padding-left: 15px;
        display: grid;
        grid-template-rows: auto 25px;

        .title {
          font-size: 20px;
          color: #222222;
          line-height: 27px;
          font-weight: 600;
        }

        .bottom {
          font-size: 14px;
          font-weight: 300;
          color: #474747;
          line-height: 20px;

          .mark {
            color: #1667ff;
          }

          img {
            height: 20px;
            width: 20px;
            position: relative;
            top: 4px;
            left: 0px;
            margin-right: 5px;
          }
        }
      }
    }

    .list:hover {
      box-shadow: 0px 0px 20px 3px #ebedf1;
      transition: all ease-in-out 0.3s;
    }

    .new_box {
      background: #f7f7f7;
    }
  }
}

.button_nav {
  height: 50px;
  box-sizing: border-box;
  padding: 20px 278px;
  display: flex;
  justify-content: start;
  align-items: center;

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .icon_box {
    display: flex;
    width: 20px;
    height: 20px;
    justify-content: center;
    align-items: center;
    border: 1px solid #2222228c;
    color: #2222228c;
    margin-right: 15px;
  }

  .back_text {
    font-size: 18px;
    font-weight: 300;
    color: #2222228c;
    line-height: 25px;
  }
}

.button_nav:hover {
  .icon_box {
    color: #1667ff;
    border: 1px solid #1667ff;
  }

  .back_text {
    color: #1667ff;
  }
}

.yuanchuang {
  width: 70px;
  height: 30px;
  background: #0064ff14;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 500;
  color: #1667ff;
  line-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.green {
  font-size: 18px;
  font-weight: 500;
  color: #474747;
  line-height: 25px;
  padding: 0 20px 0 10px;
}

.dialog {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 100;
  background: rgba(0, 0, 0, 0.48);
  top: 0;
  left: 0;
  justify-content: center;
}

.dialog,
.dialog .inner {
  display: flex;
  align-items: center;
}

.dialog .inner {
  width: 400px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.18);
  border-radius: 4px;
  justify-content: flex-start;
  flex-direction: column;
  padding: 22px 30px 16px;
  position: relative;
  background: #fff;
}

.dialog .inner > .close-icon {
  width: 14px;
  height: 14px;
  padding: 4px;
  box-sizing: content-box;
  cursor: pointer;
  top: 12px;
  right: 18px;
}

.dialog .inner > .title {
  font-size: 16px;
  font-weight: 700;
  color: #222;
  width: 100%;
  text-align: left;
}

.dialog .inner > .content {
  width: 100%;
  font-size: 14px;
  color: #222;
  text-align: left;
  line-height: 18px;
  margin-top: 10px;
}

.dialog .inner > .action-wrap {
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.dialog .inner > .action-wrap .btn {
  line-height: 32px;
}

.dialog .inner > .action-wrap .cancel-btn {
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #d9d9d9;
  color: #333;
  background: #fff;
  transition: background 0.3s;
}

.dialog .inner > .action-wrap .cancel-btn:active {
  background: #f2f2f2;
  border: 1px solid #cfcfcf;
}

.dialog .inner > .action-wrap .cancel-btn:hover {
  background: #f8f8f8;
  border: 1px solid #d3d3d3;
}

.dialog .inner > .action-wrap .btn.primary {
  margin-left: 12px;
  cursor: pointer;
  background: #24be48;
  border-radius: 4px;
  color: #fff;
  transition: background 0.3s;
}

.dialog .inner > .action-wrap .btn.primary:active {
  background: #1fa63f;
}

.dialog .inner > .action-wrap .btn.primary:hover {
  background: #21af42;
}

.btn {
  outline: none;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 0 20px;
  white-space: nowrap;
  color: #000;
  font-size: 14px;
  background: #fff;
  box-sizing: content-box;
  cursor: pointer;
  height: 32px;
  line-height: 32px;
  transition: all 0.2s ease 0s;
}

.btn:hover {
  color: #333;
  background: #f8f8f8;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
}

.btn:active {
  color: #1a1a1a;
  background: #f2f2f2;
  border-radius: 4px;
  border: 1px solid #cfcfcf;
}

.btn.primary {
  background: #24be48;
  color: #fff;
  border: 1px solid #24be48;
}

.btn.primary:hover {
  background: #21af42;
  color: #fff;
}

.btn.primary:active {
  background: #1fa63f;
}

.btn.primary.disable {
  background: #f5f5f5;
  color: #b8b8b8;
  border: 1px solid transparent;
  // cursor: not-allowed;
}

.btn.primary.disable:hover {
  opacity: 1;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  // overflow: hidden;
  // font-family: mp-quote, -apple-system-font, BlinkMacSystemFont, Helvetica Neue,
  //   PingFang SC, Hiragino Sans GB, Microsoft YaHei UI, Microsoft YaHei, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}

.tip-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #fdf5e8;
}

@media screen and (max-width: 1024px) {
  #page-root {
    width: 100%;
    height: 100%;
    background: #fff;
    padding-top: 1.09312rem;
    padding-left: 0.6832rem;
    padding-right: 0.6832rem;
    // overflow-x: hidden;
    // overflow-y: scroll;
  }

  #page-root .tip-container {
    padding: 5px 15px;
    font-size: 14px;

    font-weight: 400;
    color: #f09a47;
    line-height: 20px;
  }

  #page-root .article-container {
    height: 100%;
    margin-top: 50px;
  }

  #page-root .article-container .title {
    font-size: 1.50304rem;
    margin-bottom: 0.95648rem;
    color: #333;
  }

  #page-root .article-container .article-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1.50304rem;
    line-height: 1.3664rem;

    font-size: 0.81984rem;
  }

  #page-root .article-container .article-info .author {
    color: #576b95;
    margin-right: 0.6832rem;
  }

  #page-root .article-container .article-info .timestamp {
    color: #8d8d8d;
    margin-right: 0.40992rem;
  }

  #page-root .article-container .article-content-wrap {
    width: 100%;
    padding-bottom: 2.7328rem;
  }

  #page-root .article-container .article-content-wrap .article-content {
    color: #333;
    text-align: justify;
    font-size: 1.16144rem;

    margin-bottom: 0.6832rem;
  }

  #page-root .article-container .article-content-wrap .article-content * {
    max-width: 100% !important;
    box-sizing: border-box !important;
  }

  #page-root .article-container .article-content-wrap .article-content img {
    height: auto !important;
  }

  #page-root .article-container .article-content-wrap .article-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #page-root .article-container .article-content-wrap .article-footer .reading {
    font-size: 0.75152rem;
    color: #b6b6b6;
  }

  #page-root .article-container .article-content-wrap .article-footer .watching {
    display: flex;
    align-items: center;
  }

  #page-root
    .article-container
    .article-content-wrap
    .article-footer
    .watching
    .watch-icon {
    width: 0.95648rem;
    height: 1.09312rem;
    margin-right: 0.27328rem;
  }

  #page-root .article-container .article-content-wrap .article-footer .watching > span {
    color: #607191;
    font-size: 0.75152rem;
  }

  .dialog .inner {
    width: 80%;
  }

  .dialog .inner .content {
    margin-top: 0.6832rem;
  }

  .dialog .inner .action-wrap {
    margin-top: 1.3664rem;
  }
}

@media screen and (min-width: 1024px) {
  #page-root {
    width: 100%;
    height: 100%;
    background: #fff;
    padding-top: 32px;
    // overflow-x: hidden;
    // overflow-y: scroll;
  }

  #page-root .tip-container {
    height: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 14px;

    font-weight: 400;
    color: #f09a47;
  }

  #page-root .article-container {
    width: 900px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }

  #page-root .article-container .title {
    font-size: 22px;
    margin-bottom: 14px;
    color: #333;
  }

  #page-root .article-container .article-info {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 22px;
    line-height: 20px;
  }

  #page-root .article-container .article-info .author {
    color: #576b95;
    margin-right: 10px;
  }

  #page-root .article-container .article-info .timestamp {
    color: #8d8d8d;
    font-size: 18px;
    margin-right: 6px;
  }

  #page-root .article-container .article-content-wrap {
    width: 100%;
    padding-bottom: 40px;
  }

  #page-root .article-container .article-content-wrap .article-content {
    color: #333;
    text-align: justify;
    font-size: 17px;

    margin-bottom: 10px;
  }

  #page-root .article-container .article-content-wrap .article-content * {
    max-width: 100% !important;
    box-sizing: border-box !important;
  }

  #page-root .article-container .article-content-wrap .article-content img {
    height: auto !important;
  }

  #page-root .article-container .article-content-wrap .article-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  #page-root .article-container .article-content-wrap .article-footer .reading {
    font-size: 11px;
    color: #576b95;
  }

  #page-root .article-container .article-content-wrap .article-footer .watching {
    display: flex;
    align-items: center;
  }

  #page-root
    .article-container
    .article-content-wrap
    .article-footer
    .watching
    .watch-icon {
    width: 14px;
    height: 16px;
    margin-right: 4px;
  }

  #page-root .article-container .article-content-wrap .article-footer .watching > span {
    color: #607191;
    font-size: 11px;
  }
}

.article-content-wrap .loading-icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 40%;
  width: 2.0496rem;
  height: 2.0496rem;
}

.article-content-wrap .loading-icon.in-pc {
  width: 240px;
  height: 240px;
  top: 20%;
}

.partner_nav {
  height: 706px;
  padding: 202px 350px 150px;
  box-sizing: border-box;

  .partner_title {
    font-size: 36px;
    font-weight: 800;
    color: #3d3f55;
    line-height: 36px;
    text-align: center;
    padding-bottom: 80px;
  }

  .partner_box {
    display: grid;
    grid-gap: 80px 140px;
    grid-template-columns: repeat(4, 1fr);

    .img {
      width: 200px;
      height: 79px;
      margin-top: -30px;
    }
  }
}

#allmap {
  // 注意给dom宽高，不然地图不出来
  width: 100%;
  height: 400px;

  :deep(.BMap_cpyCtrl, .anchorBL) {
    display: none !important;
  }
}
</style>
